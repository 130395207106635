import { Image } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { decryptString } from '../../methods/utils/general-utils';

export const ImageSection = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	border: 2px solid #aaa3a3;
	border-radius: 5px;
`;
interface IImageComponent {
	secret: string;
	url: string;
	createdAt: string;
}
const ImageComponent = ({ secret, url, createdAt }: IImageComponent) => {
	const [base64, setBase64] = useState<string>();

	async function fetchBase64() {
		if (moment(createdAt).isBefore(moment('2024/07/16'))) {
			const data = decryptString(url, secret);
			setBase64(data);
		}
	}

	useEffect(() => {
		if (url) {
			fetchBase64();
		}
	}, [secret, url]);

	const chooseUrl = () => {
		if (base64 && moment(createdAt).isBefore(moment('2024/07/16'))) {
			return `data:image/png;base64,${base64}`;
		}
		return `data:image/png;base64,${url}`;
	};

	return (
		<ImageSection>
			<Image src={chooseUrl()} height="100%" width="100%" alt="doc" />
		</ImageSection>
	);
};

export default ImageComponent;
